import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import { setVocabulary, setExamples } from "./features/vocabulary";

import "./App.scss";

// const BASE_URL = "http://localhost:3001";
// https://dictionary-aliaryaie.apps.ir-thr-ba1.arvanpaas.ir/api/dictionary/search?vocabulary=method
// https://dictionary-aliaryaie.apps.ir-thr-ba1.arvanpaas.ir/api/dictionary/search/?vocabulary=method
// http://dictionary-aliaryaie.apps.ir-thr-ba1.arvanpaas.ir/api/dictionary/
// https://dictionary-aliaryaie.apps.ir-thr-ba1.arvanpaas.ir/
// const BASE_URL = "https://dictionary-aliaryaie.apps.ir-thr-ba1.arvanpaas.ir";
const BASE_URL = "https://dictionary.iran.liara.run";

const App = () => {
  return (
    <div id="App">
      <div className="main-body">
        <SearchBox />
        <VocabularyBox />
      </div>
    </div>
  );
};

const SearchBox = () => {
  const [query, setQuery] = useState("");

  const dispatch = useDispatch();
  const vocabulary = useSelector((state) => state.vocabulary);

  let american = new Audio();
  let british = new Audio();

  const sendRequest = () => {
    const dataBody = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };

    const endpoint = `/api/dictionary/search/?vocabulary=${query}`;
    const url = BASE_URL + endpoint;

    console.log("Search New Vocabulary!");

    try {
      fetch(url, dataBody)
        .then((response) => response.json())
        .then((data) => {
          if (data.hasOwnProperty("vocabulary")) {
            dispatch(setVocabulary(data));
            // setQuery(data.vocabulary);
            // console.log(data);
          }
        });
    } catch (error) {
      // console.log(error);
    }
  };

  const hitSearch = () => {
    const searchBox = document.querySelector("#search-box");
    const searchButton = document.querySelector("button#submit-search-btn");

    searchBox.addEventListener("keypress", (e) => {
      // If the User Presses the "Enter" Key on the Keyboard
      if (e.key === "Enter") {
        // Cancel the Default Action (if needed)
        e.preventDefault();
        // Trigger the Button Element with a Click
        searchButton.click();
        if (query !== "") {
          // console.log(query);
        }
      }
    });
  };

  useEffect(() => {
    hitSearch();
    let vocabStorage = JSON.parse(localStorage.getItem("vocabulary"));
    if (vocabStorage === null) {
      const dataBody = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };

      const endpoint = `/api/dictionary/`;

      const url = BASE_URL + endpoint;

      try {
        fetch(url, dataBody)
          .then((response) => response.json())
          .then((data) => {
            if (data) {
              // console.log(data.vocabulary);
              dispatch(setVocabulary(data));
              // setQuery(data.vocabulary);
            }
          });
      } catch (error) {
        // console.log(error);
      }
    } else {
      // console.log(vocabStorage.vocabulary);
      if (vocabStorage.vocabulary !== "") {
        dispatch(setVocabulary(vocabStorage));
      }
    }
    // console.log("DidMount");
  }, []);

  useEffect(() => {
    if (vocabulary.vocabulary) {
      american.src = vocabulary.voice.american;
      british.src = vocabulary.voice.british;
      // console.log(vocabulary.voice.american);
    }
  }, [vocabulary]);

  return (
    <div className="search-box">
      <h1>Vocabulary Finder</h1>
      <div className="input-box">
        <input
          type="search"
          name="search-box"
          id="search-box"
          onChange={(e) => setQuery(e.target.value)}
          required
          min={1}
          pattern="[A-Za-z-]{1,}"
          value={query}
        />
        {/* <span>{query}</span> */}
        <button id="submit-search-btn" onClick={sendRequest}>
          <i className="bi bi-search"></i>
        </button>
        <i className="input-border"></i>
      </div>
      {vocabulary ? (
        vocabulary.vocabulary !== "" ? (
          <div className="vocabulary">
            <div className="vocab-title">
              <h2>{vocabulary.vocabulary}</h2>
              <span>({vocabulary.POS})</span>
            </div>
            <div className="vocab-pronounciation">
              <span style={{ fontSize: 20 }}>{vocabulary.hyphen}</span>
              <span>[ {vocabulary.pronounceCode} ]</span>
            </div>
            <div className="vocab-sounds">
              <div className="flags">
                <img
                  className="voice"
                  onClick={() => american.play()}
                  src="Images/united-states.png"
                  alt="us-pronounciation"
                />
                <img
                  className="voice"
                  onClick={() => british.play()}
                  src="Images/united-kingdom.png"
                  alt="uk-pronounciation"
                />
              </div>
            </div>
            {vocabulary.isAWL ? (
              <span className="academic">Academic Vocabulary</span>
            ) : null}
            <br />
            <span id="vocab-score">Score : {vocabulary.score}</span>
            <p>
              <strong id="word">{vocabulary.vocabulary}</strong>
              <span id="message">
                {vocabulary.score >= 80
                  ? ` is a hight frequency used vocabulary.`
                  : vocabulary.score >= 60
                  ? ` is an important vocabulary.`
                  : vocabulary.score >= 40
                  ? ` is a normal vocabulary.`
                  : ` is just a simple vocabulary.`}
              </span>
            </p>
          </div>
        ) : null
      ) : null}
    </div>
  );
};

const VocabularyBox = () => {
  const dispatch = useDispatch();
  const vocabulary = useSelector((state) => state.vocabulary);

  const findExamples = () => {
    const dataBody = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };

    const endpoint = `/api/dictionary/examples?vocabulary=${vocabulary.vocabulary}&domain=N&offset=0&limit=6`;
    const url = BASE_URL + endpoint;

    console.log("Search for Examples!");

    try {
      fetch(url, dataBody)
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            // console.log(data);
            if (data.length > 0) {
              dispatch(setExamples(data));
            }
          }
        });
    } catch (error) {
      // console.log(error);
    }
  };

  return (
    <div className="vocab-box">
      {vocabulary.vocabulary ? (
        <div className="examples">
          {/* <h3 onClick={findExamples}>Examples</h3> */}
          {vocabulary.examples ? (
            <div className="sentences">
              {vocabulary.examples.length > 0 ? (
                vocabulary.examples.map((item) => (
                  <div className="setntence" key={item.title}>
                    <p>{item.sentence}</p>
                    <a target="_blank" href={item.link} rel="noopener">
                      {item.reference}
                    </a>
                  </div>
                ))
              ) : (
                <h3 onClick={findExamples}>Examples</h3>
              )}
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export { App };
