import { configureStore } from "@reduxjs/toolkit";

import authenticationReducer from "./authentication";
import vocabularyReducer from "./vocabulary";

export const store = configureStore({
  reducer: {
    authentication: authenticationReducer,
    vocabulary: vocabularyReducer,
  },
});
