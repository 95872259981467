import { createSlice } from "@reduxjs/toolkit";

let authStorage = JSON.parse(localStorage.getItem("authentication"));

let initialState = {
  access_token: "",
  refresh_token: "",
  isAuthorized: false,
};

if (authStorage) {
  initialState = authStorage;
}

export const authSlicer = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    setAuth: (state, action) => {
      if (action.payload.access_token && action.payload.refresh_token) {
        state.access_token = action.payload.access_token;
        state.refresh_token = action.payload.refresh_token;
        state.isAuthorized = true;
        localStorage.setItem(
          "authentication",
          JSON.stringify({
            access_token: state.access_token,
            refresh_token: state.refresh_token,
            isAuthorized: true,
          })
        );
      }
    },
    logout: (state) => {
      state.access_token = "";
      state.refresh_token = "";
      state.isAuthorized = false;

      localStorage.setItem(
        "authentication",
        JSON.stringify({
          access_token: "",
          refresh_token: "",
          isAuthorized: false,
        })
      );
    },
  },
});

// Export Actions
export const { setAuth, logout } = authSlicer.actions;
// Export Slice
export default authSlicer.reducer;
