import { createSlice } from "@reduxjs/toolkit";

let vocabStorage = JSON.parse(localStorage.getItem("vocabulary"));

let initialState = {
  vocabulary: "",
  hyphen: "",
  pronounceCode: "",
  frequency: 0,
  top_spoken: 0,
  top_written: 0,
  isAWL: false,
  score: 0,
  pos: "",
  voice: {
    american: "",
    british: "",
  },
  examples: [],
};

if (vocabStorage) {
  initialState = vocabStorage;
}

export const vocabSlicer = createSlice({
  name: "vocabulary",
  initialState,
  reducers: {
    setVocabulary: (state, action) => {
      if (action.payload) {
        // console.log(action.payload);
        state.vocabulary = action.payload.vocabulary;
        state.hyphen = action.payload.hyphen;
        state.pronounceCode = action.payload.pronounceCode;
        state.frequency = action.payload.frequency;
        state.top_spoken = action.payload.top_spoken;
        state.top_written = action.payload.top_written;
        state.isAWL = action.payload.isAWL;
        state.score = action.payload.score;
        state.POS = action.payload.POS;
        state.voice.american = action.payload.voice.american;
        state.voice.british = action.payload.voice.british;
        state.examples = [];
        localStorage.setItem("vocabulary", JSON.stringify(action.payload));
      }
    },
    setExamples: (state, action) => {
      if (action.payload) {
        // console.log(action.payload);
        state.examples = action.payload;
        // localStorage.setItem("vocabulary", JSON.stringify(state));
      }
    },
  },
});

// Export Actions
export const { setVocabulary, setExamples } = vocabSlicer.actions;
// Export Slice
export default vocabSlicer.reducer;
